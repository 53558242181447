import { Divider, Row } from 'antd'
import { IPermissionLevel } from 'entities/permissions'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarElement, DropdownElement, IconElement, MenuElement, MenuItemElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

type Props = {
  /** id или индекс ользователя */
  numberValue: number
  /** полное имя пользователя */
  fullname: string
  /** флаг определяющий видимость иконки   */
  iconShow: boolean
  /** флаг определяющий доступна ли кнопка изменения уровня пользователя */
  isChangedLevels?: boolean
  /** id разрешений */
  permissionId?: number
  /** фукнция для изменения уровня пользователя */
  handleLevelChange?: (permissionId: number, permissionLevel: IPermissionLevel) => void
  /** уровень пользователя */
  permissionLevel?: IPermissionLevel
  /** текст(название воркспейса или логин пользователя) */
  secondaryText?: string
  /** текст(статус пользователя) */
  stateText?: string
  /** флаг определяющий доступна ли иконка */
  iconDisabled?: boolean
  /** функция удаления пользователя */
  onClickIcon?: () => void
  /** функция выделения пользователя */
  onClick?: () => void
}

export const UserInfo = ({
  fullname,
  handleLevelChange,
  iconDisabled,
  iconShow,
  isChangedLevels,
  numberValue,
  onClick,
  onClickIcon,
  permissionId,
  permissionLevel,
  secondaryText,
  stateText,
}: Props) => {
  const { t } = useTranslation()
  const permissionText = permissionLevel === 'READ_ONLY' ? t('Только просмотр') : t('Редактирование')

  const changePermissionLevel = (level: IPermissionLevel, permissionId?: number) => {
    if (!isChangedLevels) {
      handleLevelChange?.(permissionId || 0, level)
    }
  }

  useEffect(() => {
    if (isChangedLevels && permissionLevel !== 'CONSULTANT') {
      changePermissionLevel('CONSULTANT', permissionId || 0)
    }
  }, [])

  const menu = (
    <StyledMenuElement>
      <MenuItemElement
        disabled={isChangedLevels}
        key="1"
        icon={permissionLevel === 'READ_ONLY' && <StyledIconElement name="checkSmall" size="md" />}
        onClick={() => changePermissionLevel('READ_ONLY', permissionId)}
      >
        {t('Только просмотр')}
      </MenuItemElement>
      <MenuItemElement
        disabled={isChangedLevels}
        key="2"
        icon={permissionLevel === 'CONSULTANT' && <StyledIconElement name="checkSmall" size="md" />}
        onClick={() => changePermissionLevel('CONSULTANT', permissionId)}
      >
        {t('Редактирование')}
      </MenuItemElement>
      <StyledDivider />
      <MenuItemElement disabled={!iconShow || iconDisabled} key="3" onClick={onClickIcon}>
        {t('Удалить')}
      </MenuItemElement>
    </StyledMenuElement>
  )
  return (
    <Item onClick={onClick}>
      <UserWrapper>
        <UserAvatar numberValue={numberValue} fullname={fullname} size={'small'} />
        <UserContainer>
          <UserPrimaryRow>{fullname}</UserPrimaryRow>
          {secondaryText !== undefined && <UserSecondaryRow>{secondaryText}</UserSecondaryRow>}
        </UserContainer>
      </UserWrapper>
      <StyledRow>
        <State style={{ marginRight: '4px' }}>
          <TextElement style={{ color: 'var(--color-text-3)' }}>{stateText}</TextElement>
        </State>
        {permissionLevel && (
          <DropdownElement overlay={menu} trigger={['click']}>
            <StyledPermissionLevel>
              {permissionText}
              <IconElement name="dropDown" size="md" />
            </StyledPermissionLevel>
          </DropdownElement>
        )}
      </StyledRow>
    </Item>
  )
}

export const Item = styled.div`
  padding: 5px 10px 5px 0;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 400px;
`

const UserPrimaryRow = styled.span`
  font-size: 12px;
  line-height: 16px;
  overflow: hidden;
  color: var(--color-text-1);
  text-overflow: ellipsis;
`

const UserSecondaryRow = styled.span`
  font-size: 11px;
  font-weight: 500;
  line-height: 12px;
  color: var(--color-text-3);
  overflow: hidden;
  text-overflow: ellipsis;
`
const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  flex-shrink: 1;
  min-width: 0;
`

const UserAvatar = styled(AvatarElement)`
  vertical-align: middle;
  margin-right: 8px;
  flex-shrink: 0;
`
const StyledIconElement = styled(IconElement)`
  margin-right: -6px;
  margin-left: -20px;
`
const StyledPermissionLevel = styled(TextElement)`
  align-items: center;
  display: flex;
  cursor: pointer;
  color: var(--color-text-3) !important;
`

const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  overflow: hidden;
`

const StyledRow = styled(Row)`
  flex-shrink: 0;
`
export const State = styled.div`
  display: flex;
  align-items: center;
`
export const Actions = styled.div`
  display: grid;
  place-items: center;
`

const StyledDivider = styled(Divider)`
  margin: 0 !important;
`

export const StyledMenuElement = styled(MenuElement)`
  &.ant-menu-root.ant-menu-vertical {
    padding: 0;
  }

  &.ant-menu-vertical .ant-menu-item,
  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    padding: 0 16px 0 24px;
  }
`
