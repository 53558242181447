import { ShareFormContainer } from 'features/share/ShareFormContainer'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalComponent } from 'shared/ui/modal'
import styled from 'styled-components/macro'

import { ShareTitle } from './children/ShareTitle'

type Props = {
  /** id кейчас */
  caseId: number
  /** состояние открыт/закрыт */
  open?: boolean
  /** Функция меняющая состояни откртыт/закрыт */
  setIsOpen: (open: boolean) => void
}

const StyledModal = styled(ModalComponent)`
  top: 54px;

  .ant-modal-content {
    padding: 8px 16px 8px 16px;
    width: 432px;
  }
  .ant-modal-header {
    padding: 0 0 10px !important;
  }
  .ant-modal-close {
    top: 8px;
  }
  .ant-modal-body {
    max-height: calc(100vh - 158px);
  }
  padding-bottom: 0;
`

export const ShareModal: FC<Props> = ({ caseId, open, setIsOpen }) => {
  const [modal, setModal] = useState<boolean>(false)
  const { t } = useTranslation()

  const handleCancel = () => {
    setIsOpen(false)
  }

  return (
    <StyledModal
      title={
        <ShareTitle
          onClose={handleCancel}
          titleText={modal ? t('Выберите врача') : t('Добавить врачей к просмотру случая')}
          modal={modal}
          onClick={() => setModal(false)}
        />
      }
      visible={open}
      footer={null}
      closable={false}
      onCancel={handleCancel}
      destroyOnClose
      data-testid="share-modal"
    >
      <ShareFormContainer caseId={caseId} modal={modal} setModal={setModal} />
    </StyledModal>
  )
}
