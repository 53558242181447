import { Divider, Form } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { ICasePermission, IPermissionActionType, IPermissionLevel } from 'entities/permissions'
import { notices } from 'features/notices'
import { findUserByCriteria } from 'features/share/lib/common'
import UserAutoCompleteContainer from 'features/share/UserAutoCompleteContainer'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectUrlSlideId, viewerPageSlice } from 'pages/viewer'
import { StompClientContext } from 'processes/stomp'
import React, { memo, useContext, useEffect, useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'shared/lib/i18n/i18n'
import { ButtonElement, CheckboxElement, IconElement, SpaceElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import ICase from 'types/ICase'
import { IOptions } from 'types/IOptions'
import IUser from 'types/IUser'

import { LinkedUsers } from './children/LinkedUsers'
import { UsersToInvite } from './children/UsersToInvite'

const t = i18next.t

const { Item: FormItem, useForm } = Form

const MINIMAL_AUTOCOMPLETE_LENGTH = 2

type Props = {
  /** функция отправки приглашения */
  onSubmit: UseMutateAsyncFunction<void, unknown, { permission: IPermissionActionType }, unknown>
  /** функция удаления */
  onDelete: (permissionId: number) => void
  /** флаг отслеживающий запрос */
  isFetching: boolean
  /** флаг отслеживающий загрузку */
  isLoading: boolean
  /** флаг отслеживающий доступ к удалению */
  isDeleting: boolean
  /** флаг отслеживающий загрузку отправки приглашения  */
  isSending: boolean
  /** данные случая */
  caseRecord: ICase
  /** фукнция для изменения уровня пользователя */
  onChangeLevel?: ({
    permissionId,
    permissionLevel,
  }: {
    permissionId: number
    permissionLevel: IPermissionLevel
  }) => void
  /** информация разрешений/доступности пользователей, к которым отправил кейс */
  permissions?: ICasePermission[]
  /** информация о текущем пользователе */
  currentUser?: IUser
  /** информация о пользователях */
  users?: IOptions
  /** флаг отслеживающий открыто ли окно модалки  */
  modal: boolean
  /** фукнция для открытия/закрытия окна модалки */
  setModal: (modal: boolean) => void
}

export const ShareForm = memo(
  ({
    caseRecord,
    currentUser,
    isDeleting,
    isFetching,
    isLoading,
    isSending,
    modal,
    onChangeLevel,
    onDelete,
    onSubmit,
    permissions,
    setModal,
    users,
  }: Props) => {
    const dispatch = useDispatch()
    const workspaceId = useCurrentWorkspaceId()
    const urlSlideId = useSelector(selectUrlSlideId)
    const [form] = useForm()
    const linkPermission = permissions?.find((p) => p.type === 'LINK')
    const otherPermissions = permissions?.filter((p) => p.type !== 'LINK')
    const currentWorkspaceName = useTypedSelector((state) => state.workspaces.currentWorkspace)?.name
    const onCopyLink = () => {
      const linkPermission = permissions?.find((p) => p.type === 'LINK')
      if (linkPermission?.link) {
        navigator.clipboard.writeText(linkPermission?.link).then(() => {
          notices.info({ message: t('Скопировано!') })
        })
      }
    }

    const onFinish = async (data: { permission: IPermissionActionType }) => {
      try {
        //Antd bug: form permission.email is '' after copying and pasting in form
        if (data.permission.type === 'EMAIL' && data.permission.email === '')
          return form.setFields([
            {
              errors: [t('Неправильный формат email')],
              name: 'permission',
            },
          ])
        await onSubmit(data)
        setModal(false)
      } catch (error: any) {
        if (error?.response?.status === 409) {
          form.setFields([
            {
              errors: [t('На указанный email приглашение уже было отправлено')],
              name: 'permission',
            },
          ])
        }
        if (error?.response?.status === 400) {
          form.setFields([
            {
              errors: [t('Неправильный формат email')],
              name: 'permission',
            },
          ])
        }
      }
    }

    const handleDelete = (permissionId: number) => {
      onDelete(permissionId)
    }

    const handleLevelChange = (permissionId: number, permissionLevel: IPermissionLevel) => {
      onChangeLevel?.({ permissionId, permissionLevel })
    }
    const clearError = () => {
      form.setFields([
        {
          errors: undefined,
          name: 'permission',
        },
      ])
    }
    const { publish } = useContext(StompClientContext)
    const [options, setOptions] = useState<IOptions>({ isAutocompleteSource: false, usersGroupByWorkspaceName: {} })

    useEffect(() => {
      users && setOptions(users)
    }, [users])

    const getInitialLinksStatus = () => {
      if (!permissions) return false
      if (permissions.length === 0) return false
      if (permissions?.find((item) => item.permissionLevel === 'DISABLED' && item.type === 'LINK')) return false
      return !!linkPermission
    }
    const getInitialEditingStatus = () =>
      !!permissions?.find((item) => item.permissionLevel === 'CONSULTANT' && item.type === 'LINK')
    /** Стейт чекбокса управляющий возможностью создания ссылки шеринга  */
    const [linkPermissionEnabling, setLinkPermissionEnabling] = useState<boolean>(getInitialLinksStatus())
    /** Стейт чекбокса управляющий доступами (permissionLevel) при создании ссылки шеринга  */
    const [isEditing, setEditing] = useState<boolean>(getInitialEditingStatus())
    /** Стейт чекбокса управляющий доступами (permissionLevel) при шеринге выбранному юзеру */
    const [isEditingOnSubmit, setEditingOnSubmit] = useState<boolean>(true)
    const permissionLevelOnSubmit = isEditingOnSubmit ? 'CONSULTANT' : 'READ_ONLY'

    useEffect(() => {
      const permission: IPermissionActionType = form.getFieldValue('permission')
      permission &&
        permission?.workspaceId !== workspaceId &&
        form.setFieldsValue({
          permission: { ...permission, level: permissionLevelOnSubmit },
        })
    }, [permissionLevelOnSubmit])
    const linkPermissionHandler = (isLinkPermissionEnabling?: boolean, isEditingEnabling?: boolean) => {
      onSubmit(
        {
          permission: {
            level:
              isEditingEnabling && isLinkPermissionEnabling
                ? 'CONSULTANT'
                : isLinkPermissionEnabling
                ? 'READ_ONLY'
                : 'DISABLED',
            type: 'LINK',
          },
        },
        {
          onSuccess: () => {
            setLinkPermissionEnabling(!!isLinkPermissionEnabling)
            setEditing(!!isEditingEnabling)
          },
        },
      )
    }

    const onSearch = (query: string, uuid: string) => {
      setModal(true)
      if (query.length > MINIMAL_AUTOCOMPLETE_LENGTH) {
        publish(`/app/autocomplete/users/${uuid}`, { query }, { 'x-oc-workspace-id': String(workspaceId) })
      } else if (query.length === 0) {
        /** После полного удаления текста в поле поиска показывать список МУ и врачей */
        users && setOptions(users)
      }
      form.setFieldsValue({
        permission: {
          email: query,
          level: permissionLevelOnSubmit,
          type: 'EMAIL',
          userId: undefined,
          workspaceId: undefined,
        },
      })
      clearError()
    }
    const onFocus = () => {
      const permission: IPermissionActionType = form.getFieldValue('permission')
      if (!permission || (permission?.type === 'EMAIL' && permission?.email.length === 0)) {
        /** После полного удаления текста в поле поиска показывать список МУ и врачей */
        setModal(true)
        users && setOptions(users)
      } else if (['USER', 'USER_ACQUIRED'].includes(permission?.type)) {
        form.setFieldsValue({
          permission: undefined,
        })
        setModal(true)
        users && setOptions(users)
      }
    }
    const onSelect = (userId: number, workspaceName: string) => {
      const selectedUser = users && findUserByCriteria({ criteria: 'userId', options: users, searchKey: userId })
      /** Если пользователь из текущего воркспейса */
      if (workspaceName === currentWorkspaceName && selectedUser) {
        clearError()
        form.setFieldsValue({
          permission: {
            email: undefined,
            level: 'CONSULTANT',
            type: 'USER_ACQUIRED',
            userId: selectedUser?.userId,
            workspaceId: selectedUser?.workspaceId,
          },
        })
      } else {
        clearError()
        form.setFieldsValue({
          permission: {
            email: selectedUser?.email,
            level: permissionLevelOnSubmit,
            type: 'USER',
            /** Если пользователя нет в списке воркспесов (внешний пользователь) */
            /** Отправляется userId напрямую */
            userId: selectedUser?.userId || userId,
            workspaceId: selectedUser?.workspaceId,
          },
        })
      }
    }

    useEffect(() => {
      urlSlideId && dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(true))
      return () => {
        urlSlideId && dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(false))
      }
    }, [])

    return (
      <SpinElement spinning={isDeleting || isLoading || isSending}>
        <InviteForm onFinish={onFinish as any} form={form} data-testid="share-form">
          <InviteByEmail>
            <FormItem name="permission">
              <UserAutoCompleteContainer
                onChange={clearError}
                options={options}
                setOptions={setOptions}
                onSelect={onSelect}
                onFocus={onFocus}
                onSearch={onSearch}
                value={form.getFieldValue('permission')}
                close={() => setModal(false)}
              />
            </FormItem>
            <FormItem>
              <LoginFormControl type="primary" htmlType="submit">
                {t('Отправить')}
              </LoginFormControl>
            </FormItem>
          </InviteByEmail>
          {modal && (
            <EditPermissionCheckbox
              isEditing={isEditingOnSubmit}
              linkPermissionHandler={(linkEnabling, editingEnabling) => setEditingOnSubmit(!!editingEnabling)}
              linkPermissionEnabling={linkPermissionEnabling}
              linkDescription={false}
              enabled={true}
            />
          )}
          <Divider />
          {modal ? (
            <UsersToInvite
              otherPermissions={otherPermissions}
              options={options}
              onSelect={onSelect}
              initialWorkspaceName={currentWorkspaceName}
              caseOwnerId={caseRecord?.user?.userId}
            />
          ) : (
            <>
              <LinkedUsers
                otherPermissions={otherPermissions}
                currentUser={currentUser}
                caseRecord={caseRecord}
                handleDelete={handleDelete}
                handleLevelChange={handleLevelChange}
                isFetching={isFetching}
                isLoading={isLoading}
              />
              <Divider />
              <CheckboxContainer>
                <CheckboxElement
                  checked={linkPermissionEnabling}
                  onChange={(e) => linkPermissionHandler(e.target.checked, isEditing)}
                >
                  {t('Разрешить добавление врачей по ссылке')}
                </CheckboxElement>
                {linkPermissionEnabling && (
                  <EditPermissionCheckbox
                    isEditing={isEditing}
                    linkPermissionHandler={linkPermissionHandler}
                    linkPermissionEnabling={linkPermissionEnabling}
                    linkDescription={true}
                    enabled={true}
                  />
                )}
              </CheckboxContainer>
              {linkPermissionEnabling && (
                <>
                  <SpaceElement size={8} />
                  <Footer>
                    {linkPermission && <ButtonElement onClick={onCopyLink}>{t('Скопировать ссылку')}</ButtonElement>}
                  </Footer>
                </>
              )}
            </>
          )}
        </InviteForm>
      </SpinElement>
    )
  },
)
type EditProps = {
  /** Функция для переключения чекбокса и изменения уровня пользователя для ссылки */
  linkPermissionHandler: (isLinkPermissionEnabling?: boolean, isEditingEnabling?: boolean) => void
  /** Флаг определяющий доступ добавления пользователей по ссылке */
  linkPermissionEnabling: boolean
  /** Флаг определяющий, включена ли возможность редактирования */
  isEditing: boolean
  /** Варианты текста */
  linkDescription: boolean
  /** Блокировка опции при совпадении воркспейса */
  enabled: boolean
}

const EditPermissionCheckbox = ({
  enabled,
  isEditing,
  linkDescription,
  linkPermissionEnabling,
  linkPermissionHandler,
}: EditProps) => (
  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <CheckboxElement
      disabled={!enabled}
      style={{ margin: 0 }}
      checked={isEditing}
      onChange={(e) => linkPermissionHandler(linkPermissionEnabling, e.target.checked)}
    >
      {t(`Возможность редактировать случай `) + (linkDescription ? t(`по ссылке`) : t(`для врача`))}
    </CheckboxElement>
    <TooltipElement
      overlayStyle={{ whiteSpace: 'pre-wrap', width: 200 }}
      placement="bottom"
      title={`Врачу будут доступны:\n${t(HELP_ICON_TOOLTIP_TEXT.writeComments)}\n${t(
        HELP_ICON_TOOLTIP_TEXT.workAnnotations,
      )}\n${t(HELP_ICON_TOOLTIP_TEXT.shareCase)}\n${t(HELP_ICON_TOOLTIP_TEXT.writeConclusion)}\n${t(
        HELP_ICON_TOOLTIP_TEXT.showLabel,
      )}`}
    >
      <span style={{ height: 16 }}>
        <StyledIconElement size={'md'} name="helpSmall" />
      </span>
    </TooltipElement>
  </div>
)

const HELP_ICON_TOOLTIP_TEXT = {
  shareCase: t('Возможность делиться случаем'),
  showLabel: t('Просмотр этикетки'),
  workAnnotations: t('Работа с аннотациями'),
  writeComments: t('Написание комментариев'),
  writeConclusion: t('Написание заключения'),
}

const LoginFormControl = styled(ButtonElement)`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
  padding: 4px 16px;
`

const InviteByEmail = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  width: 400px;

  & > :first-child {
    flex-grow: 1;
  }
`

const InviteForm = styled(Form)``

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 135px;
  font-size: 12px;
  line-height: 16px;
`

const CheckboxContainer = styled.div`
  display: grid;
  height: 40px;
`
const StyledIconElement = styled(IconElement)`
  cursor: pointer;
  color: var(--color-text-3);
  &:hover {
    color: var(--color-text-1);
  }
`
