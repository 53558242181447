import { Divider, Dropdown, Menu } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import icons from 'assets/icons'
import { useChangeCaseStatusMutation, useDeleteCaseMutation } from 'features/cases'
import {
  useAddCasesToFavoritesMutation,
  useDeleteCasesFromFavoritesMutation,
} from 'features/cases-management/api/query'
import {
  EAllCasesTabType,
  EDistributionTabType,
  EFavoriteList,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import { TCaseFavoritesOperationType } from 'features/cases-management/types/TCaseApi'
import { CaseData } from 'features/cases-management/ui/cases-table/CasesTable'
import {
  handleDeclineClick,
  handleEditClick,
  handleRemoveClick,
  handleStatusModalClick,
  handleToArchiveClick,
  handleUrgentlyClick,
  reportCreationModalClick,
} from 'features/new-preview-panel/common'
import { selectedReportByCaseSelector } from 'features/reports'
import { useReportQuery, useReportsDataQuery } from 'features/reports/api/query'
import { ShareModal } from 'features/share/ui/ShareModal'
import SlideUploadButtonContainer from 'features/thumbnails/SlideUploadButtonContainer'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import i18next from 'i18next'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'shared/lib/hooks'
import { isGuestHandler } from 'shared/lib/local-storage'
import { getBooleanOrDefaultAccess, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { IconElement } from 'shared/ui/kit'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components'
import { ECaseStageQuery, ICaseDTO, ICaseRelation } from 'types/ICase'
import IUserRole from 'types/IUserRole'
import { SettingsNameEnum } from 'types/IWorkspaces'

interface IDropDownProps {
  children: React.ReactNode
  onUploadImageClick?: () => void
  setCurrentCase: Dispatch<SetStateAction<CaseData>>
  caseData: ICaseDTO | null
  refetchCases: () => void
  caseFormModal: React.MutableRefObject<ModalHandle | null>
  declineModal: React.MutableRefObject<ModalHandle | null>
  statusHistoryModal: React.MutableRefObject<ModalHandle | null>
  reportCreationModal: React.MutableRefObject<ModalHandle | null>
}

const StyledMenuItem = styled(Menu.Item)`
  &:hover {
    background: var(--color-bg-4);
  }

  .ant-dropdown-menu-title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 5px;

    svg {
      height: revert-layer;
    }
  }
`

const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`
/** Максимально допустимое число слайдов в кейсе */
const MAX_SLIDES_LENGTH = 20

const StyledIconElement = styled(IconElement)`
  color: var(--color-text-1);
`
const t = i18next.t

export const DropDown: React.FC<IDropDownProps> = ({
  /** Данные выбранного случая. */
  caseData,
  /** Ссылка на модальное окно формы случая. */
  caseFormModal,
  children,
  /** Ссылка на модальное окно отказа. */
  declineModal,
  /**  Функция для повторного получения данных. */
  refetchCases,
  /** Ссылка на модальное заключения. */
  reportCreationModal,
  /** Функция для установки текущего случая. */
  setCurrentCase,
  /** Ссылка на модальное окно истории статусов. */
  statusHistoryModal,
}) => {
  const dispatch = useDispatch()
  const workspaceId = useCurrentWorkspaceId()
  const caseId = caseData?.caseId
  const tabType = useQueryParams().get('tab') as EMyCasesTabType | EDistributionTabType | EAllCasesTabType
  const { data: caseReports } = useReportsDataQuery(caseId, 'PLATFORM')
  const selectedReportIndex = useTypedSelector(selectedReportByCaseSelector(Number(caseId)))
  const selectedReportId =
    caseReports?.[selectedReportIndex]?.medicalReportId || Number(caseReports?.[0]?.medicalReportId)
  const { mutate: changeStatus } = useChangeCaseStatusMutation(caseId || 0)
  const { mutate: deleteCase } = useDeleteCaseMutation(caseId || 0, refetchCases)
  const { mutate: addFavorites } = useAddCasesToFavoritesMutation()
  const { mutate: deleteFavorites } = useDeleteCasesFromFavoritesMutation()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const isCurrentWorkspace = workspaceId === caseData?.workspace?.workspaceId
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const isAssigned = caseData?.stage === ECaseStageQuery.ASSIGNED
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const isCaseOwner = caseData?.assignedDoctor?.userId === currentUserId
  const authorities = useTypedSelector((state) => state.user.authorities)
  const isGuest = isGuestHandler(authorities)
  const lisMode = useLisMode()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const isDoctor = authorities?.includes(IUserRole.ROLE_USER)
  const isAssignment = authorities?.includes(IUserRole.ROLE_CASE_ASSIGNMENT)
  const isCompleted = caseData?.stage === ECaseStageQuery.COMPLETED
  const { data: selectedReport = caseReports?.[0] } = useReportQuery(caseId, selectedReportId, 'PLATFORM')
  const shareEnable = !isGuest
  const isOwner = caseData?.relation === ICaseRelation.OWNER
  const editEnable = caseData?.relation === ICaseRelation.OWNER && lisMode === 'none'
  const archiveEnable = isCurrentWorkspace && authorities?.includes(IUserRole.ROLE_ARCHIVE_MANAGER)
  const deleteEnable =
    isDoctor &&
    isCurrentWorkspace &&
    lisMode === 'none' &&
    getBooleanOrDefaultAccess(SettingsNameEnum.CanRemoveCase, true, currentWorkspace) &&
    tabType !== EAllCasesTabType.DELETED
  const changeUrgentEnable = getBooleanOrDefaultAccess(SettingsNameEnum.CaseRouting, true, currentWorkspace)
    ? isAssignment && !isCompleted
    : isDoctor && !isCompleted
  const historyEnable = (isAssignment || isDoctor) && isCurrentWorkspace
  const declineEnable =
    isCurrentWorkspace &&
    isCaseOwner &&
    isAssigned &&
    isDoctor &&
    !isCompleted &&
    isCaseRouting &&
    !getBooleanOrDefaultAccess(SettingsNameEnum.CaseStageManagement, true, currentWorkspace)
  const removeFromFavoritesEnable =
    isCaseRouting && isDoctor && caseData?.favoriteLists.includes(EFavoriteList.WATCHING)
  const addToFavoritesEnable =
    isCaseRouting &&
    isDoctor &&
    !caseData?.favoriteLists.includes(EFavoriteList.WATCHING) &&
    tabType !== EAllCasesTabType.DELETED
  const reportCreationEnable =
    (lisMode === 'none' && !selectedReport?.signed) || (lisMode === 'gemotest' && !caseReports?.length)
  const archiveHandle = async () => {
    await handleToArchiveClick(caseData?.status === 'OPEN' ? 'ARCHIVE' : 'OPEN', changeStatus, refetchCases)
  }
  const deleteHandle = async () => {
    await handleRemoveClick(deleteCase, refetchCases)
  }
  const changeUrgentHandle = async () => {
    caseId && (await handleUrgentlyClick(workspaceId, refetchCases, caseId, !caseData?.urgent))
  }
  const handleContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
  }
  const openEditModal = () => {
    handleEditClick(dispatch, caseFormModal)
  }
  const openDeclineModal = () => {
    handleDeclineClick(declineModal)
  }
  const openStatusHistoryModal = () => {
    if (caseData?.caseId && caseData?.workspace?.workspaceId) {
      setCurrentCase({ caseId: caseData?.caseId, name: caseData?.name, wsId: Number(caseData?.workspace?.workspaceId) })
    }
    handleStatusModalClick(statusHistoryModal)
  }
  const openReportCreationModal = () => {
    reportCreationModalClick(reportCreationModal)
  }

  const handleFavoritesChange = (
    operationType: TCaseFavoritesOperationType,
    list: EFavoriteList = EFavoriteList.WATCHING,
  ) => {
    if (caseId) {
      const payload = { caseIds: [caseId], list }
      operationType === 'add' ? addFavorites(payload) : deleteFavorites(payload)
    }
  }

  const menu = caseData ? (
    <Menu data-testid={`case-action-drop-down`}>
      {declineEnable && (
        <StyledMenuItem data-testid={'refuse'} onClick={openDeclineModal}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'refuse'} />
          </StyledIconWrapper>
          <div>{t('Отказаться')}</div>
        </StyledMenuItem>
      )}
      {changeUrgentEnable && (
        <StyledMenuItem data-testid={caseData.urgent ? 'uncito' : 'cito'} onClick={changeUrgentHandle}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={caseData.urgent ? 'uncito' : 'cito'} />
          </StyledIconWrapper>
          <div>{caseData.urgent ? t('Несрочно') : t('Срочно')}</div>
        </StyledMenuItem>
      )}
      {reportCreationEnable && (
        <StyledMenuItem data-testid={'reportSmall'} onClick={openReportCreationModal}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'reportSmall'} />
          </StyledIconWrapper>
          <div>{t('Добавить заключение')}</div>
        </StyledMenuItem>
      )}
      {historyEnable && (
        <StyledMenuItem data-testid={'historySmall'} onClick={openStatusHistoryModal}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'historySmall'} />
          </StyledIconWrapper>
          <div>{t('История')}</div>
        </StyledMenuItem>
      )}
      {isOwner && (
        <StyledMenuItem data-testid={'addImageSmall'}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'addImageSmall'} />
          </StyledIconWrapper>
          <SlideUploadButtonContainer
            disabled={caseData.slides.length >= MAX_SLIDES_LENGTH}
            caseId={caseData.caseId}
            key="addButton"
            buttonType={'default'}
          >
            <div>{t('Загрузить изображение')}</div>
          </SlideUploadButtonContainer>
        </StyledMenuItem>
      )}
      {shareEnable && (
        <StyledMenuItem data-testid={'shareSmallCircle'} onClick={() => setIsModalOpen(true)}>
          <div style={{ alignItems: 'center', display: 'flex', gap: 5, justifyContent: 'flex-start' }}>
            <StyledIconWrapper>
              <StyledIconElement size={'md'} name={'shareSmallCircle'} />
            </StyledIconWrapper>
            <div>{t('Поделиться')}</div>
          </div>
        </StyledMenuItem>
      )}
      {editEnable && (
        <StyledMenuItem data-testid={'editSmall2'} onClick={openEditModal}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'editSmall2'} />
          </StyledIconWrapper>
          <div>{t('Редактировать')}</div>
        </StyledMenuItem>
      )}
      {addToFavoritesEnable && (
        <StyledMenuItem data-testid={'star16'} onClick={() => handleFavoritesChange('add')}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'star16'} />
          </StyledIconWrapper>
          <div>{t('Добавить в отслеживаемое')}</div>
        </StyledMenuItem>
      )}
      {removeFromFavoritesEnable && (
        <StyledMenuItem data-testid={'notStar'} onClick={() => handleFavoritesChange('delete')}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'notStar'} />
          </StyledIconWrapper>
          <div>{t('Перестать отслеживать')}</div>
        </StyledMenuItem>
      )}
      {archiveEnable && (
        <StyledMenuItem data-testid={'archiveSmall2'} onClick={archiveHandle}>
          <StyledIconWrapper>
            <StyledIconElement size={'md'} name={'archiveSmall2'} />
          </StyledIconWrapper>
          <div>{caseData.status === 'OPEN' ? t('В архив') : t('Вернуть из архива')}</div>
        </StyledMenuItem>
      )}
      {deleteEnable && (
        <>
          <StyledMenuItem style={{ padding: 0 }}>
            <Divider style={{ margin: 0, pointerEvents: 'none', width: '100%' }} type="horizontal" />
          </StyledMenuItem>

          <StyledMenuItem data-testid={'deleteSmall2'} danger onClick={deleteHandle}>
            <StyledIconWrapper>
              <icons.deleteSmall2 />
            </StyledIconWrapper>
            <div>{t('Удалить')}</div>
          </StyledMenuItem>
        </>
      )}
    </Menu>
  ) : (
    <></>
  )

  return (
    <>
      <Dropdown
        overlay={menu}
        trigger={['contextMenu']}
        placement="topLeft"
        overlayClassName="ant-dropdown-menu-blurry"
        overlayStyle={{
          borderRadius: 5,
        }}
      >
        <div onContextMenu={handleContextMenu}>{children}</div>
      </Dropdown>
      <ShareModal open={isModalOpen} caseId={Number(caseId)} setIsOpen={setIsModalOpen} />
    </>
  )
}
