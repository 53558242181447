import { Popover } from 'antd'
import type { PopoverProps } from 'antd/es/popover'
import { CustomBtn, ShareTitle } from 'features/share/ui/children/ShareTitle'
import React, { FC } from 'react'

type Props = {
  onClose: () => void
  titleText: string
  modal?: boolean
  shareModal?: boolean
  onClick?: () => void
  // Дополнительная кнопка в заголовке перед "крестиком"
  customBtn?: CustomBtn
} & PopoverProps

export const PopoverWithCloseBtn: FC<Props> = ({
  customBtn,
  modal,
  onClick,
  onClose,
  shareModal,
  titleText,
  ...rest
}) => (
  <Popover
    onVisibleChange={(visible) => {
      if (!visible && shareModal) onClose()
    }}
    placement="bottomRight"
    trigger="click"
    title={<ShareTitle onClose={onClose} titleText={titleText} modal={modal} onClick={onClick} customBtn={customBtn} />}
    {...rest}
  />
)
