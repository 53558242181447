import icons from 'assets/icons'
import React from 'react'
import { ButtonElement, IconElement, Row, TitleElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

export const StyledButton = styled(ButtonElement)`
  &.ant-btn-icon-only {
    width: 16px;
    height: 16px;
    padding: 0;
    color: var(--color-text-3);
  }
`
export const StyledIconElement = styled(IconElement)`
  cursor: pointer;
  margin-right: 8px;
  & g path {
    fill: var(--color-text-3);
  }
`

export const ShareTitle = ({
  customBtn,
  modal,
  onClick,
  onClose,
  titleText,
}: {
  onClose: () => void
  titleText: string
  modal?: boolean
  customBtn?: CustomBtn
  onClick?: () => void
}) => (
  <Row style={{ justifyContent: 'space-between' }}>
    <div style={{ display: 'flex', flex: 1 }}>
      {modal && <StyledIconElement fill={'secondary'} size={'md'} name="undoSmall" onClick={onClick} />}
      <TitleElement level={4}>{titleText}</TitleElement>
    </div>
    {customBtn && (
      <StyledButton
        type="text"
        style={{ marginRight: 8 }}
        icon={<IconElement style={customBtn?.style} size={'md'} name={customBtn?.icon} />}
        onClick={customBtn.onClick}
      />
    )}
    <StyledButton type="text" icon={<IconElement size={'md'} name="cross16" />} onClick={onClose} />
  </Row>
)

export type CustomBtn = {
  // Иконка дополнительной кнопки
  icon: keyof typeof icons
  // Обработчик клика дополнительной кнопки
  onClick: () => void
  // Стиль для кнопки
  style?: any
}
